import { customizer } from '@consumer/api'

import imageActiviesTopActivities from '@consumer/assets/images/header-v2/activities/top-activities.png'
import imageActivitiesAmusementParks from '@consumer/assets/images/header-v2/activities/amusement-parks.png'
import imageActivitiesBowling from '@consumer/assets/images/header-v2/activities/bowling.png'
import imageActivitiesCasinos from '@consumer/assets/images/header-v2/activities/casinos.png'
import imageActivitiesGolf from '@consumer/assets/images/header-v2/activities/golf.png'
import imageBrandsAldi from '@consumer/assets/images/header-v2/brands/aldi.png'
import imageBrandsAllegiantAir from '@consumer/assets/images/header-v2/brands/allegiant-air.png'
import imageBrandsHEB from '@consumer/assets/images/header-v2/brands/h-e-b.png'
import imageBrandsJetBlue from '@consumer/assets/images/header-v2/brands/jetblue.png'
import imageBrandsLouisVuitton from '@consumer/assets/images/header-v2/brands/louis-vuitton.png'
import imageBrandsMcDonalds from '@consumer/assets/images/header-v2/brands/mcdonalds.png'
import imageBrandsSpiritAirlines from '@consumer/assets/images/header-v2/brands/spirit-airlines.png'
import imageBrandsVrbo from '@consumer/assets/images/header-v2/brands/vrbo.png'
import imageCitiesBoston from '@consumer/assets/images/header-v2/cities/boston.png'
import imageCitiesChicago from '@consumer/assets/images/header-v2/cities/chicago.png'
import imageCitiesDenver from '@consumer/assets/images/header-v2/cities/denver.png'
import imageCitiesHouston from '@consumer/assets/images/header-v2/cities/houston.png'
import imageCitiesLosAngeles from '@consumer/assets/images/header-v2/cities/los-angeles.png'
import imageCitiesNewYork from '@consumer/assets/images/header-v2/cities/new-york.png'
import imageCitiesPortland from '@consumer/assets/images/header-v2/cities/portland.png'
import imageCitiesSanDiego from '@consumer/assets/images/header-v2/cities/san-diego.png'
import imageCitiesSanFrancisco from '@consumer/assets/images/header-v2/cities/san-francisco.png'
import imageCitiesWashington from '@consumer/assets/images/header-v2/cities/washington.png'
import imageRestaurantsAmerican from '@consumer/assets/images/header-v2/restaurants/american.png'
import imageRestaurantsBars from '@consumer/assets/images/header-v2/going-out/bars.png'
import imageRestaurantsBreakfastAndBrunch from '@consumer/assets/images/header-v2/restaurants/breakfast-and-brunch.png'
import imageRestaurantsFastFood from '@consumer/assets/images/header-v2/restaurants/fast-food.png'
import imageRestaurantsFineDining from '@consumer/assets/images/header-v2/restaurants/fine-dining.png'
import imageRestaurantsFoodDelivery from '@consumer/assets/images/header-v2/restaurants/food-delivery.png'
import imageRestaurantsTrendyRestaurant from '@consumer/assets/images/header-v2/restaurants/trendy-restaurant.png'
import imageShoppingDepartmentStores from '@consumer/assets/images/header-v2/shopping/department-stores.png'
import imageShoppingGrocery from '@consumer/assets/images/header-v2/shopping/grocery.png'
import imageShoppingHomeDecor from '@consumer/assets/images/header-v2/shopping/home-decor.png'
import imageShoppingMensClothing from '@consumer/assets/images/header-v2/shopping/mens-clothing.png'
import imageShoppingPets from '@consumer/assets/images/header-v2/shopping/pets.png'
import imageShoppingTopStores from '@consumer/assets/images/header-v2/shopping/top-stores.png'
import imageShoppingWomensClothing from '@consumer/assets/images/header-v2/shopping/womens-clothing.png'
import imageSpaAndBeautyAllSpaAndBeauty from '@consumer/assets/images/header-v2/spa-and-beauty/all-spa-and-beauty.png'
import imageSpaAndBeautyDaySpas from '@consumer/assets/images/header-v2/spa-and-beauty/day-spas.png'
import imageSpaAndBeautyMassage from '@consumer/assets/images/header-v2/spa-and-beauty/massage.png'
import imageSpaAndBeautyNailSalons from '@consumer/assets/images/header-v2/spa-and-beauty/nail-salons.png'

import imageUniversalCashGiftsAndCards from '@consumer/assets/images/header-v2/universal/cash-gifts-and-cards.png'
import imageUniversalDigitalGiftCards from '@consumer/assets/images/header-v2/universal/digital-gift-cards.png'
import imageUniversalEGiftsAndECards from '@consumer/assets/images/header-v2/universal/e-gifts-and-e-cards.png'
import imageUniversalEmailGiftCards from '@consumer/assets/images/header-v2/universal/email-gift-cards.png'
import imageUniversalMailableGiftCards from '@consumer/assets/images/header-v2/universal/mailable-gift-cards.png'
import imageUniversalOnlineGiftCards from '@consumer/assets/images/header-v2/universal/online-gift-cards.png'
import imageUniversalPrintableGiftCards from '@consumer/assets/images/header-v2/universal/printable-gift-cards.png'
import imageUniversalTextableGifts from '@consumer/assets/images/header-v2/universal/textable-gifts.png'
import imageUniversalVirtualGiftCards from '@consumer/assets/images/header-v2/universal/virtual-gift-cards.png'

import imageBuyAGiftAnyStore from '@consumer/assets/images/header-v2/buy-a-gift/any-store.png'
import imageBuyAGiftAnySpa1 from '@consumer/assets/images/header-v2/buy-a-gift/any-spa-1.png'
import imageBuyAGiftAnyRestaurant1 from '@consumer/assets/images/header-v2/buy-a-gift/any-restaurant-1.png'
import imageBuyAGiftAnyActivity1 from '@consumer/assets/images/header-v2/buy-a-gift/any-activity-1.png'
import imageBuyAGiftTravel from '@consumer/assets/images/header-v2/buy-a-gift/travel.png'

export const searchBarFocused = ref(false)
export const userMenuFocused = ref(false)
export const categoryBarFocused = ref(false)

export interface Suggestion {
  label: string
  city?: string
  icon?: string
  image?: string
}

export interface Category {
  title: string
  icon: string
  links: Array<{
    name: string
    path: string
    image?: string
    icon?: string
  }>
  buyAGift?: {
    label: string
    path: string
    image: string
  }
}

export const categories: Category[] = [
  {
    title: 'Restaurants',
    icon: 'v6-light-utensils',
    links: [
      {
        name: 'Top US Restaurants',
        path: '/restaurant-gift-cards',
        image: imageRestaurantsTrendyRestaurant,
      },
      {
        name: 'Fast Food',
        path: '/fast-food-gift-cards',
        image: imageRestaurantsFastFood,
      },
      {
        name: 'Food Delivery',
        path: '/food-delivery-gift-cards',
        image: imageRestaurantsFoodDelivery,
      },
      {
        name: 'American',
        path: '/american-restaurant-gift-cards',
        image: imageRestaurantsAmerican,
      },
      {
        name: 'Fine Dining',
        path: '/fine-dining-gift-cards',
        image: imageRestaurantsFineDining,

      },
      {
        name: 'Bars',
        path: '/bar-gift-cards',
        image: imageRestaurantsBars,
      },
      {
        name: 'Breakfast & Brunch',
        path: '/breakfast-brunch-gift-cards',
        image: imageRestaurantsBreakfastAndBrunch,
      },
    ],
    buyAGift: {
      label: 'Any Restaurant',
      path: customizer.show.path({
        query: {
          giftlyVersion: 3,
          gplace: 'Any restaurant',
          'giftly_metadata[tagline]': 'Any restaurant',
        },
      }),
      image: imageBuyAGiftAnyRestaurant1,
    },
  },
  {
    title: 'Travel',
    icon: 'v6-light-island-tropical',
    links: [
      {
        name: 'Airlines & Flights',
        path: '/airline-gift-cards',
        image: 'https://assets2.giftly.com/giftly-assets/v2-categories/travel/airline.png',
      },
      {
        name: 'Hotels',
        path: '/hotel-gift-cards',
        image: 'https://assets2.giftly.com/giftly-assets/v2-categories/travel/hotels.png',
      },
      {
        name: 'Rideshare',
        path: '/rideshare-gift-cards',
        image: 'https://assets2.giftly.com/giftly-assets/v2-categories/travel/car-rental.png',
      },
      {
        name: 'Tours',
        path: '/tour-gift-cards',
        image: 'https://assets2.giftly.com/giftly-assets/v2-categories/travel/tours.png',
      },
      {
        name: 'Gas',
        path: '/gas-gift-cards',
        image: 'https://assets2.giftly.com/giftly-assets/v2-categories/travel/gas.png',
      },
      {
        name: 'Ski Resorts',
        path: '/ski-resort-gift-cards',
        image: 'https://assets2.giftly.com/giftly-assets/v2-categories/travel/ski-resorts.png',
      },
    ],
    buyAGift: {
      label: 'Travel',
      path: customizer.show.path({
        query: {
          giftlyVersion: 3,
          'giftly_metadata[tagline]': 'Travel',
        },
      }),
      image: imageBuyAGiftTravel,
    },
  },
  {
    title: 'Spa & Beauty',
    icon: 'v6-light-spa',
    links: [
      {
        name: 'Nail Salons',
        path: '/nail-salon-gift-cards',
        image: imageSpaAndBeautyNailSalons,
      },
      {
        name: 'Spas',
        path: '/day-spa-gift-cards',
        image: imageSpaAndBeautyDaySpas,
      },
      {
        name: 'Massages',
        path: '/massage-gift-cards',
        image: imageSpaAndBeautyMassage,
      },
      {
        name: 'All Spa & Beauty',
        path: '/spa-beauty-gift-cards',
        image: imageSpaAndBeautyAllSpaAndBeauty,
      },
    ],
    buyAGift: {
      label: 'Any Spa',
      path: customizer.show.path({
        query: {
          giftlyVersion: 3,
          gplace: 'Any Spa',
          'giftly_metadata[tagline]': 'Any Spa',
        },
      }),
      image: imageBuyAGiftAnySpa1,
    },
  },
  {
    title: 'Activities',
    icon: 'v6-light-film',
    links: [
      {
        name: 'Top Activities',
        path: '/activity-gift-cards',
        image: imageActiviesTopActivities,
      },
      {
        name: 'Amusement Parks',
        path: '/amusement-park-gift-cards',
        image: imageActivitiesAmusementParks,
      },
      {
        name: 'Bowling',
        path: '/bowling-gift-cards',
        image: imageActivitiesBowling,
      },
      {
        name: 'Casinos',
        path: '/casino-gift-cards',
        image: imageActivitiesCasinos,
      },
      {
        name: 'Golf',
        path: '/golf-gift-cards',
        image: imageActivitiesGolf,
      },
    ],
    buyAGift: {
      label: 'Any Activity',
      path: customizer.show.path({
        query: {
          giftlyVersion: 3,
          'giftly_metadata[tagline]': 'Any Activity',
        },
      }),
      image: imageBuyAGiftAnyActivity1,
    },
  },
  {
    title: 'Shopping',
    icon: 'v6-light-bag-shopping',
    links: [
      {
        name: 'Top Stores',
        path: '/shopping-gift-cards',
        image: imageShoppingTopStores,
      },
      {
        name: 'Department Store',
        path: '/department-store-gift-cards',
        image: imageShoppingDepartmentStores,
      },
      {
        name: 'Home Decor',
        path: '/home-decor-gift-cards',
        image: imageShoppingHomeDecor,
      },
      {
        name: 'Grocery',
        path: '/grocery-gift-cards',
        image: imageShoppingGrocery,
      },
      {
        name: 'Pets',
        path: '/pet-gift-cards',
        image: imageShoppingPets,
      },
      {
        name: 'Men\'s Clothing',
        path: '/mens-clothing-gift-cards',
        image: imageShoppingMensClothing,
      },
      {
        name: 'Women\'s Clothing',
        path: '/womens-clothing-gift-cards',
        image: imageShoppingWomensClothing,
      },
    ],
    buyAGift: {
      label: 'Any Store',
      path: customizer.show.path({
        query: {
          giftlyVersion: 3,
          'giftly_metadata[tagline]': 'Any Store',
        },
      }),
      image: imageBuyAGiftAnyStore,
    },
  },
  {
    title: 'Universal',
    icon: 'v6-light-globe',
    links: [
      {
        name: 'Cash Gifts & Cards',
        path: '/lp/cash-gift-cards',
        image: imageUniversalCashGiftsAndCards,
      },

      {
        name: 'E Gifts & E Cards',
        path: '/lp/e-gift-cards',
        image: imageUniversalEGiftsAndECards,
      },
      {
        name: 'Textable Gifts',
        path: '/lp/text-message-gift-cards',
        image: imageUniversalTextableGifts,
      },
      {
        name: 'Email Gift & Cards',
        path: '/lp/email-gift-cards',
        image: imageUniversalEmailGiftCards,
      },
      {
        name: 'Printable Gift & Cards',
        path: '/lp/printable-gift-cards',
        icon: 'v6-light-gift',
        image: imageUniversalPrintableGiftCards,
      },
      {
        name: 'Mailable Gift & Cards',
        path: '/lp/mailable-gift-cards',
        image: imageUniversalMailableGiftCards,
      },
      {
        name: 'Online Gift & Cards',
        path: '/lp/online-gift-cards',
        image: imageUniversalOnlineGiftCards,
      },
      {
        name: 'Digital Gift & Cards',
        path: '/lp/digital-gift-cards',
        image: imageUniversalDigitalGiftCards,
      },
      {
        name: 'Virtual Gift & Cards',
        path: '/lp/virtual-gift-cards',
        image: imageUniversalVirtualGiftCards,
      },
    ],
  },
  {
    title: 'Brands',
    icon: 'v6-light-copyright',
    links: [
      {
        name: 'Vrbo',
        path: '/gift-card/vrbo',
        image: imageBrandsVrbo,
      },
      {
        name: 'Allegiant Air',
        path: '/gift-card/allegiant-air',
        image: imageBrandsAllegiantAir,
      },
      {
        name: 'JetBlue',
        path: '/gift-card/jetblue',
        image: imageBrandsJetBlue,
      },
      {
        name: 'Louis Vuitton',
        path: '/gift-card/louis-vuitton',
        image: imageBrandsLouisVuitton,
      },
      {
        name: 'Spirit Airlines',
        path: '/gift-card/spirit-airlines',
        image: imageBrandsSpiritAirlines,
      },
      {
        name: 'Aldi',
        path: '/gift-card/aldi',
        image: imageBrandsAldi,
      },
      {
        name: 'McDonalds',
        path: '/gift-card/mcdonalds',
        image: imageBrandsMcDonalds,
      },
      {
        name: 'H-E-B',
        path: '/gift-card/h-e-b',
        image: imageBrandsHEB,
      },
      {
        name: 'All Brands',
        path: '/brands/top-brands-gift-cards',
        icon: 'v6-light-store',
      },
    ],
  },
  {
    title: 'Top Cities',
    icon: 'v6-light-city',
    links: [
      {
        name: 'New York',
        path: '/new-york-ny-gift-cards',
        image: imageCitiesNewYork,
      },
      {
        name: 'San Francisco',
        path: '/san-francisco-ca-gift-cards',
        image: imageCitiesSanFrancisco,
      },
      {
        name: 'Chicago',
        path: '/chicago-il-gift-cards',
        image: imageCitiesChicago,
      },
      {
        name: 'Los Angeles',
        path: '/los-angeles-ca-gift-cards',
        image: imageCitiesLosAngeles,
      },
      {
        name: 'Washington',
        path: '/washington-dc-gift-cards',
        image: imageCitiesWashington,
      },
      {
        name: 'Denver',
        path: '/denver-co-gift-cards',
        image: imageCitiesDenver,
      },
      {
        name: 'Houston',
        path: '/houston-tx-gift-cards',
        image: imageCitiesHouston,
      },
      {
        name: 'Boston',
        path: '/boston-ma-gift-cards',
        image: imageCitiesBoston,
      },
      {
        name: 'San Diego',
        path: '/san-diego-ca-gift-cards',
        image: imageCitiesSanDiego,
      },
      {
        name: 'Portland',
        path: '/portland-or-gift-cards',
        image: imageCitiesPortland,
      },
      {
        name: 'All Cities',
        path: '/top-cities',
        icon: 'v6-light-city',
      },
    ],
  },
]

export const pageTitle = reactive<{
  text?: string | null
  href?: string
}>({})
