<script setup lang="ts">import { ref as _ref } from 'vue';

import type { Suggestion } from '@consumer/logic/v2/header'

import MobileInputField from './MobileInputField.vue'
import SuggestionCategory from './SuggestionCategory.vue'
import SuggestionCity from './SuggestionCity.vue'

const category = defineModel<string>('category')
const location = defineModel<string>('location')

let suggestCategory = _ref(false)
let suggestLocation = _ref(false)

const selectSuggestedCategory = (suggestion: Suggestion) => {
  category.value = suggestion.label
  if (suggestion.city) {
    location.value = suggestion.city
  }
}

const selectSuggestedCity = (suggestion: Suggestion) => {
  location.value = suggestion.label
}

const emit = defineEmits(['close'])

const onSubmit = () => {
  emit('close')
}

const onCancel = () => {
  emit('close')
}

const onInputCategory = () => {
  if (category.value) {
    suggestCategory.value = false
  }
  else {
    suggestCategory.value = true
  }
}

const onInputLocation = () => {
  if (location.value) {
    suggestLocation.value = false
  }
  else {
    suggestLocation.value = true
  }
}
</script>

<template>
  <form action="/search" class="absolute inset-0 flex flex-col pt-[72px]">
    <div
      :class="[
        'bg-white flex gap-1 p-1 border-1 border-solid border-v2-grey-cool shadow-v2sm',
        'flex-col items-start rounded-xl',
        'mx-[16px]'
      ]"
    >
      <MobileInputField
        v-model="category"
        name="category"
        icon="new-magnifying-glass"
        placeholder="Business, category or interest"
        class="h-[52px]"
        @focus="suggestCategory = true"
        @blur="suggestCategory = false"
        @input="onInputCategory"
      />

      <div
        :class="[
          'w-full h-[1px] bg-grey-300',
          suggestCategory || suggestLocation ? 'opacity-0' : 'opacity-100',
        ]"
      />

      <MobileInputField
        v-model="location"
        name="city"
        icon="new-map-pin"
        placeholder="Neighborhood, city, state or zip"
        class="h-[52px]"
        @focus="suggestLocation = true"
        @blur="suggestLocation = false"
        @input="onInputLocation"
      />
    </div>

    <div class="flex-1 py-[16px] overflow-y-auto mx-[16px] ">
      <Transition name="slide-down">
        <SuggestionCategory
          v-show="suggestCategory"
          @select="selectSuggestedCategory"
        />
      </Transition>
      <Transition name="slide-down">
        <SuggestionCity
          v-show="suggestLocation"
          @select="selectSuggestedCity"
        />
      </Transition>
    </div>

    <div class="space-y-2 border-t-1 border-t-solid border-t-v2-grey-cool px-[16px] pt-[16px]">
      <V2Button
        variant="primary"
        type="submit"
        label="Search"
        class="w-full"
        @click="onSubmit"
      />
      <button
        type="reset"
        class="w-full !h-56px font-semibold !text-[18px] text-v2-primary hover:text-v2-active"
        @click.prevent="onCancel"
      >
        Cancel
      </button>
    </div>
  </form>
</template>
