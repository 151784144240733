<script lang="ts" setup>import { ref as _ref } from 'vue';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

import { categoryBarFocused, type Category } from '@consumer/logic/v2/header'
import BuyAGift from '@consumer/modules/v2/layout/Header/BuyAGift.vue'

import CategoryButton from './Button.vue'

withDefaults(defineProps<{
  category: Category
}>(), {  })

const open = _ref(false)
watchEffect(() => {
  categoryBarFocused.value = open.value
})
</script>

<template>
  <Popover
    @mouseenter="open = true"
    @mouseleave="open = false"
    @click="open = !open"
  >
    <PopoverButton
      :active="open"
      :as="CategoryButton"
      :category="category"
      @click="open = !open"
    />
    <Transition
      enterActiveClass="transition duration-200 ease-in-out"
      enterFromClass="translate-y-1 opacity-0"
      enterToClass="translate-y-0 opacity-100"
      leaveActiveClass="transition duration-150 ease-in-out"
      leaveFromClass="translate-y-0 opacity-100"
      leaveToClass="translate-y-1 opacity-0"
    >
      <div v-show="open">
        <PopoverPanel static class="absolute left-1/2 transform -translate-x-1/2">
          <V2List :rows="4" class="max-w-screen-lg mt-[10px]">
            <V2ListItem
              v-for="subCategory in category.links"
              :key="subCategory.name"
              as="a"
              :href="subCategory.path"
              class="w-[248px]"
              :label="subCategory.name"
              :icon="subCategory.icon"
              :image="subCategory.image"
            />
            <li v-if="category.buyAGift" class="row-span-4">
              <BuyAGift
                :label="category.buyAGift.label"
                :path="category.buyAGift.path"
                :image="category.buyAGift.image"
              />
            </li>
          </V2List>
        </PopoverPanel>
      </div>
    </Transition>
  </Popover>
</template>
