<script setup lang="ts">import { ref as _ref } from 'vue';

import { type Category, categories } from '@consumer/logic/v2/header'
import { Swiper, SwiperSlide } from 'swiper/vue'
import CategoryBarButton from './CategoryBar/Button.vue'
import CategoryBarMobileModal from './CategoryBar/MobileModal.vue'

import 'swiper/css'

let mobileModalCategory = _ref<Category | null>(null)

const openMobileModal = (category: Category) => {
  mobileModalCategory.value = category
}
</script>

<template>
  <div class="w-full flex justify-center relative pt-v2-4">
    <Swiper
      :slidesPerView="'auto'"
      :spaceBetween="12"
    >
      <SwiperSlide
        v-for="category, key in categories"
        :key="key"
        class="header-v2-category-swiper-slide"
      >
        <CategoryBarButton :category="category" class="!w-auto p-v2-2" @click="openMobileModal(category)"/>
      </SwiperSlide>
    </Swiper>

    <CategoryBarMobileModal v-model:category="mobileModalCategory"/>
  </div>
</template>

<style lang="scss" scoped>
.header-v2-category-swiper-slide {
  width: auto;
}
</style>
