<script setup lang="ts">import { ref as _ref } from 'vue';

import { openHowGiftlyWorksModal } from '@consumer/services/modals'
import UserMenuMobileModal from '@consumer/modules/v2/layout/Header/UserMenu/MobileModal.vue'
import type { CurrentUser } from '@consumer/types/serializers'
import UserMenuButton from './UserMenu/MenuButton.vue'
import UserMenuBuyAGift from './UserMenu/BuyAGift.vue'
import UserMenuItem from './UserMenu/MenuItem.vue'

withDefaults(defineProps<{
  currentUser?: CurrentUser | null
}>(), {  })

let open = _ref(false)

const howItWorksModal = () => {
  open.value = false
  openHowGiftlyWorksModal()
}
</script>

<template>
  <div>
    <div
      class="relative inline-block text-left"
      @click="open = !open"
    >
      <UserMenuButton v-model:open="open" :currentUser="currentUser"/>
    </div>

    <UserMenuMobileModal v-model:open="open">
      <div class="p-v2-4 pt-v2-10 flex flex-col">
        <div class="py-v2-6 space-y-[12px] border-b-1 border-b-solid border-b-v2-grey-cool">
          <UserMenuItem
            :label="currentUser ? `My Account` : `Sign Up`"
            icon="v6-light-user"
            iconColor="#00A2F1"
            iconBgColor="#F3F9FF"
            :url="currentUser ? `/my-account/purchases` : `/signup`"
            class="px-[24px] py-[12px]"
          />
          <UserMenuItem
            :label="currentUser ? `Logout` : `Login`"
            icon="v6-light-arrow-right-from-bracket"
            iconColor="#00A2F1"
            iconBgColor="#F3F9FF"
            :url="currentUser ? `/signout` : `/signin`"
            class="px-[24px] py-[12px]"
          />
        </div>
        <div class="py-[24px] space-y-[12px]">
          <UserMenuItem
            label="Browse"
            icon="v6-light-gift"
            iconColor="#61BF49"
            iconBgColor="#E4FEDD"
            url="/browse"
            class="px-[24px] py-[12px]"
          />
          <UserMenuItem
            label="How it works"
            icon="v6-light-circle-info"
            iconColor="#00A2F1"
            iconBgColor="#F3F9FF"
            url="/how-it-works"
            class="px-[24px] py-[12px]"
            @click.prevent="howItWorksModal"
          />
          <UserMenuItem
            label="Help"
            icon="v6-light-headset"
            iconColor="#A675C3"
            iconBgColor="#F7F0FA"
            url="/support"
            class="px-[24px] py-[12px]"
          />
          <UserMenuItem
            label="Redeem"
            icon="v6-light-gift-card"
            iconColor="#FFBC10"
            iconBgColor="#FFF6DE"
            url="/redeem"
            class="px-[24px] py-[12px]"
          />
          <UserMenuItem
            label="For Business"
            icon="v6-light-briefcase"
            iconColor="#AE3B00"
            iconBgColor="#FFDFCE"
            url="/corp"
            class="px-[24px] py-[12px]"
          />
        </div>
        <div class="flex-1">
          <UserMenuBuyAGift class="!w-full !h-full min-h-[320px]"/>
        </div>
      </div>
    </UserMenuMobileModal>
  </div>
</template>
