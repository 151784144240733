<script setup lang="ts">import { toRef as _toRef } from 'vue';

import { isDesktop } from '@consumer/utils/breakpoints'

import { useWindowScroll } from '@vueuse/core'
import type { CurrentUser } from '@consumer/types/serializers'
import CategoryBar from './Header/CategoryBar.vue'
import GiftlyLogo from './Header/GiftlyLogo.vue'
import SearchBarDesktop from './Header/SearchBarDesktop.vue'
import SearchBarMobile from './Header/SearchBarMobile.vue'
import UserMenu from './Header/UserMenu.vue'

withDefaults(defineProps<{
  currentUser?: CurrentUser | null
}>(), {  })

const __$temp_1 = (useWindowScroll()),
  y = _toRef(__$temp_1, 'y');
const scrolled = computed(() => y.value > 104)
</script>

<template>
  <div
    :class="[
      'fixed top-0 w-full z-100 bg-white',
      scrolled ? 'lg:shadow-v2md' : null
    ]"
  >
    <V2Container class="!p-0 w-full">
      <Transition name="slide-down">
        <div
          v-show="isDesktop || (!isDesktop && !scrolled)"
          :class="[
            'w-full',
            'h-[58px] mt-[8px] px-[12px] pt-[10px] pb-[2px]',
            'lg:h-[84px] lg:px-[24px]',
            'flex items-end'
          ]"
        >
          <div class="w-full flex items-center justify-between h-[60px]">
            <GiftlyLogo/>

            <div class="hidden lg:block">
              <SearchBarDesktop/>
            </div>

            <UserMenu :currentUser="currentUser"/>
          </div>
        </div>
      </Transition>
      <div class="px-[12px] py-[8px] lg:hidden">
        <SearchBarMobile/>
      </div>
    </V2Container>
  </div>

  <div class="h-[124px] lg:h-[94px]"/>

  <CategoryBar/>
</template>
