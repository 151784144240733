<script setup lang="ts">import { ref as _ref } from 'vue';

import {
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue'
import { whenever } from '@vueuse/core'

const open = defineModel<boolean>('open')

const onClose = () => open.value = false

const closeButtonEl = _ref<HTMLButtonElement>()
whenever(open, () => nextTick(() => closeButtonEl.value?.focus()))
</script>

<template>
  <TransitionRoot :show="open">
    <TransitionChild
      as="template"
      enter="transition-opacity duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <dialog
        class="fixed inset-0 w-full h-full bg-v2-primary bg-opacity-40 z-110"
        open
      >
        <div class="relative w-full h-full overflow-y-auto">
          <div class="bg-v2-primary bg-opacity-40" @click="onClose"/>
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-150 transform origin-right"
            enterFrom="-translate-x-0 scale-x-0"
            enterTo="-translate-x-full scale-x-1"
            leave="transition ease-in-out duration-150 transform"
            leaveFrom="translate-x-0 scale-x-1"
            leaveTo="translate-x-full scale-x-2"
          >
            <div
              :class="[
                'bg-white',
                'mt-v2-3 ml-v2-3',
                'rounded-l-v2lg',
                'relative'
              ]"
            >
              <button
                ref="closeButtonEl"
                autofocus
                type="button"
                class="absolute top-v2-6 right-v2-4 outline-none ring-0 z-1001"
                @click="onClose"
              >
                <span class="sr-only">Close search</span>
                <V2Icon name="v6-light-xmark" size="md" class="text-v2-secondary hover:text-v2-primary"/>
              </button>

              <slot/>
            </div>
          </TransitionChild>
        </div>
      </dialog>
    </TransitionChild>
  </TransitionRoot>
</template>
