
<script setup lang="ts">
import InputField from './InputField.vue'

withDefaults(defineProps<{
  name: string
  icon?: string | null
  placeholder?: string
}>(), {  })

const model = defineModel<string>()

const emit = defineEmits(['focus', 'blur'])

const onFocus = () => {
  emit('focus')
}

const onBlur = () => {
  emit('blur')
}
</script>

<template>
  <InputField
    v-model="model"
    :name="name"
    :icon="icon"
    :placeholder="placeholder"
    class="header-v2-search-input-field-mobile"
    @focus="onFocus"
    @blur="onBlur"
  >
    <template #addonRight>
      <button
        :class="[
          'transition-all duration-200 ease-in-out',
          model ? 'opacity-100' : 'opacity-0'
        ]"
        @click.prevent="model = ''"
      >
        <V2Icon name="new-x-circle" size="md" class="text-v2-secondary"/>
      </button>
    </template>
  </InputField>
</template>

<style lang="scss">
.header-v2-search-input-field-mobile {
  input {
    padding-right: 48px !important;
  }
}
</style>
