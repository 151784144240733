<script lang="ts" setup>import { toRef as _toRef } from 'vue';

import { Head } from '@inertiajs/vue3'

import { useSharedData, usePage } from '@consumer/composables/sharedData'
import { categoryBarFocused, searchBarFocused, userMenuFocused } from '@consumer/logic/v2/header'
import { cssPropertiesForTheme, setCurrentTheme } from '@consumer/composables/themes'

import Header from '@consumer/modules/layout/Header.vue'
import V2Header from '@consumer/modules/v2/layout/Header.vue'
import V2Overlay from '@consumer/modules/v2/layout/Overlay.vue'
import V2Footer from '@consumer/modules/v2/layout/Footer.vue'
import BaseLayout from '@consumer/layouts/BaseLayout.vue'
import SupportHeader from '@consumer/modules/layout/SupportHeader.vue'
import RecipientHeader from '@consumer/modules/layout/RecipientHeader.vue'

defineOptions({
  inheritAttrs: false,
})

const __$temp_1 = (useSharedData()),
  currentUser = _toRef(__$temp_1, 'currentUser'),
  featureFlags = _toRef(__$temp_1, 'featureFlags');

const __$temp_2 = (usePage()),
  component = _toRef(__$temp_2, 'component'),
  url = _toRef(__$temp_2, 'url');

const theme = setCurrentTheme()

const headerType = computed(() => {
  if ([
    'MultiPlaceGiftIdea',
    'BuyerLanding',
  ].includes(component.value)) {
    return 'product'
  }
  else if (component.value === 'Checkout') {
    return 'checkout'
  }
  else if (
    url.value.startsWith('/support')
    || url.value.startsWith('/entercode')
    || url.value.startsWith('/card/activate')
  ) {
    return 'simple'
  }
  else {
    return 'default'
  }
})

const footerType = computed(() => {
  if (component.value === 'Checkout') {
    return 'checkout'
  }
  else if (
    url.value.startsWith('/support')
    || url.value.startsWith('/entercode')
    || url.value.startsWith('/card/activate')
  ) {
    return 'simple'
  }
  else {
    return 'default'
  }
})
</script>

<template>
  <Head>
    <title>Giftly: Online gift cards, printable gift cards, email gift cards, e-gift cards</title>
  </Head>

  <BaseLayout :style="cssPropertiesForTheme(theme)">
    <V2Header v-if="featureFlags.headerV2" :type="headerType" :currentUser="currentUser"/>
    <!-- TODO: Remove this after launching header V2 -->
    <template v-else>
      <SupportHeader v-if="url.startsWith('/support')"/>
      <RecipientHeader v-else-if="['RecipientGiftLookup', 'RecipientGiftVisaCardActivate'].includes(component)"/>
      <Header v-else/>
    </template>
    <!-- END TODO -->

    <div class="relative flex-1">
      <slot/>

      <V2Footer
        :type="footerType"
      />

      <V2Overlay
        v-if="featureFlags.headerV2"
        v-show="categoryBarFocused || searchBarFocused || userMenuFocused"
      />
    </div>
  </BaseLayout>
</template>
