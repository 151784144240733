<script setup lang="ts">import { ref as _ref } from 'vue';

import {
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue'
import { whenever } from '@vueuse/core'

const open = defineModel<boolean>('open')

const onClose = () => open.value = false

const closeButtonEl = _ref<HTMLButtonElement>()
whenever(open, () => nextTick(() => closeButtonEl.value?.focus()))
</script>

<template>
  <TransitionRoot :show="open">
    <TransitionChild
      as="template"
      enter="transition-opacity duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <dialog
        class="fixed inset-0 w-full h-full bg-v2-primary bg-opacity-40 z-110"
        open
      >
        <div class="relative w-full h-full">
          <div class="absolute inset-0 bg-v2-primary bg-opacity-40" @click="onClose"/>
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-150 transform"
            enterFrom="translate-y-full scale-y-0"
            enterTo="translate-y-0 scale-y-100"
            leave="transition ease-in-out duration-150 transform"
            leaveFrom="translate-y-0 scale-y-100"
            leaveTo="translate-y-full scale-y-0"
          >
            <div
              :class="[
                'absolute inset-0 bg-white',
                'top-v2-6 pt-v2-6 pb-v2-2 rounded-t-v2lg'
              ]"
            >
              <button
                ref="closeButtonEl"
                autofocus
                type="button"
                class="absolute top-[24px] right-[16px] outline-none ring-0 z-1001"
                @click="onClose"
              >
                <span class="sr-only">Close search</span>
                <V2Icon name="v6-light-xmark" size="md" class="text-v2-secondary hover:text-v2-primary"/>
              </button>

              <slot/>

              <!-- This element is to trick the browser to not focus any focusable element inside the modal -->
              <button class="opacity-0 absolute bottom-0"/>
            </div>
          </TransitionChild>
        </div>
      </dialog>
    </TransitionChild>
  </TransitionRoot>
</template>
