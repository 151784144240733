<script setup lang="ts">
import type { CurrentUser } from '@consumer/types/serializers'

const open = defineModel<{ open: boolean }>()

withDefaults(defineProps<{
  currentUser?: CurrentUser | null
}>(), {  })
</script>

<template>
  <button
    :class="[
      'flex flex-row items-center gap-v2-3',
      'p-v2-3',
      'rounded-v2md border-1 border-solid border-v2-grey-cool',
      'hover:text-v2-primary',
      open ? 'text-v2-primary' : 'text-v2-secondary'
    ]"
  >
    <div
      v-if="currentUser?.initials"
      :class="[
        'text-[12px] text-v2-blue-royal font-semibold',
        'w-[26px] h-[26px] bg-v2-blue-cool rounded-v2sm',
        'flex items-center justify-center',
      ]"
    >
      {{ currentUser.initials }}
    </div>
    <V2Icon v-else name="v6-light-user"/>
    <V2Icon name="v6-light-bars"/>
  </button>
</template>
