<script lang="ts" setup>
import { isDesktop } from '@consumer/utils/breakpoints'

withDefaults(defineProps<{
  category: {
    title: string
    icon: string
    links: {
      name: string
      icon?: string
      image?: string
      path: string
    }[]
    buyNow?: {
      label: string
      path: string
      variant?: string
    }
  }
  active?: boolean
}>(), {  })

const label = computed(() => __props.category.title)
const icon = computed(() => __props.category.icon)
</script>

<template>
  <button
    class="
      flex flex-col gap-3 items-center group
      hover:text-v2-primary
      outline-none
      w-[96px]
      group
    "
    :class="active ? 'text-v2-primary' : 'text-v2-secondary'"
  >
    <div>
      <V2Icon :name="icon" :size="isDesktop ? 'lg' : 'md'"/>
    </div>
    <div
      :class="[
        'inline-block text-[12px] font-semibold pb-2 w-full whitespace-nowrap',
        'underline-2 underline-v2-active underline-offset-8',
        'group-hover:underline',
        active ? 'underline' : ''
      ]"
    >
      {{ label }}
    </div>
  </button>

</template>
