<script setup lang="ts">
import imgCitiesNewYork from '@consumer/assets/images/header-v2/cities/new-york.png'
import imgCitiesSanFrancisco from '@consumer/assets/images/header-v2/cities/san-francisco.png'
import imgCitiesChicago from '@consumer/assets/images/header-v2/cities/chicago.png'
import imgCitiesLosAngeles from '@consumer/assets/images/header-v2/cities/los-angeles.png'
import imgCitiesWashington from '@consumer/assets/images/header-v2/cities/washington.png'
import imgCitiesDenver from '@consumer/assets/images/header-v2/cities/denver.png'
import imgCitiesHouston from '@consumer/assets/images/header-v2/cities/houston.png'
import imgCitiesBoston from '@consumer/assets/images/header-v2/cities/boston.png'
import imgCitiesSanDiego from '@consumer/assets/images/header-v2/cities/san-diego.png'
import imgCitiesPortland from '@consumer/assets/images/header-v2/cities/portland.png'
import type { Suggestion } from '@consumer/logic/v2/header'

const suggestions = [
  {
    label: 'Nearby',
    icon: 'v6-light-location-dot',
  },
  {
    label: 'New York, NY',
    image: imgCitiesNewYork,
  },
  {
    label: 'San Francisco, CA',
    image: imgCitiesSanFrancisco,
  },
  {
    label: 'Chicago, IL',
    image: imgCitiesChicago,
  },
  {
    label: 'Los Angeles, CA',
    image: imgCitiesLosAngeles,
  },
  {
    label: 'Washington, DC',
    image: imgCitiesWashington,
  },
  {
    label: 'Denver, CO',
    image: imgCitiesDenver,
  },
  {
    label: 'Houston, TX',
    image: imgCitiesHouston,
  },
  {
    label: 'Boston, MA',
    image: imgCitiesBoston,
  },
  {
    label: 'San Diego, CA',
    image: imgCitiesSanDiego,
  },
  {
    label: 'Portland, OR',
    image: imgCitiesPortland,
  },
]
const emit = defineEmits<{(e: 'select', value: Suggestion): void}>()

const selectSuggestion = (suggestion: Suggestion) => {
  emit('select', suggestion)
}
</script>

<template>
  <V2List class="w-full">
    <V2ListItem
      v-for="suggestion in suggestions"
      :key="suggestion.label"
      class="w-full cursor-pointer"
      :label="suggestion.label"
      :icon="suggestion.icon"
      :image="suggestion.image"
      @click="selectSuggestion(suggestion)"
    />
  </V2List>
</template>
