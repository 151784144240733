<script setup lang="ts">import { ref as _ref, toRef as _toRef } from 'vue';

import { useWindowScroll } from '@vueuse/core'

import { searchBarFocused, type Suggestion } from '@consumer/logic/v2/header'
import SearchBarDesktopInputField from './SearchBar/DesktopInputField.vue'

const focusedCategory = _ref(false)
const focusedLocation = _ref(false)
const compact = defineModel<boolean>('compact')

const __$temp_1 = (useWindowScroll()),
  y = _toRef(__$temp_1, 'y');
const scrolled = computed(() => y.value > 104)

watch(
  () => [scrolled?.value, focusedCategory.value, focusedLocation.value],
  () => {
    if (!scrolled?.value) {
      compact.value = false
    }
    else {
      compact.value = !focusedCategory.value && !focusedLocation.value
    }

    searchBarFocused.value = focusedCategory.value || focusedLocation.value
  },
)

const category = defineModel<string>('category')
const location = defineModel<string>('location')

const selectSuggestedCategory = (suggestion: Suggestion) => {
  category.value = suggestion.label
  if (suggestion.city) {
    location.value = suggestion.city
  }
}

const selectSuggestedCity = (suggestion: Suggestion) => {
  location.value = suggestion.label
}
</script>

<template>
  <div
    :class="[
      'flex justify-center items-center',
      'transition transition-all duration-300',
    ]"
  >
    <form
      action="/search"
      :class="[
        'bg-white p-[4px]',
        'border-1 border-solid border-v2-grey-cool rounded-v2md shadow-v2sm',
        'flex flex-row gap-1 items-center justify-center',
        compact ? 'h-44px' : 'h-[60px]'
      ]"
    >
      <SearchBarDesktopInputField
        v-model="category"
        name="category"
        :compact="compact"
        :placeholder="compact ? `What they like` : `Business, category or interest`"
        @focus="focusedCategory = true"
        @blur="focusedCategory = false"
        @selectSuggestion="selectSuggestedCategory"
      />

      <div
        :class="[
          'h-[24px] flex w-[1px] bg-grey-300',
          focusedCategory || focusedLocation ? 'opacity-0' : 'opacity-100',
        ]"
      />

      <SearchBarDesktopInputField
        v-model="location"
        name="city"
        :compact="compact"
        :placeholder="compact ? `Where they live` : `Neighborhood, city, state or zip`"
        @focus="focusedLocation = true"
        @blur="focusedLocation = false"
        @selectSuggestion="selectSuggestedCity"
      />

      <div
        class="hidden lg:block h-full"
        :style="{
          aspectRatio: '1/1',
        }"
      >
        <V2Button
          variant="primary"
          type="submit"
          icon="v6-light-magnifying-glass"
          size="small"
          :class="[
            'h-full w-full !p-0'
          ]"
        />
      </div>
    </form>
  </div>
</template>
