<script setup lang="ts">import { ref as _ref } from 'vue';

import MobileModal from '@consumer/modules/v2/layout/Header/MobileModal.vue'
import SearchBarMobileForm from './SearchBar/MobileForm.vue'

const open = _ref(false)
</script>

<template>
  <!-- Placeholder search bar opens modal on click-->
  <div class="w-full" @click="open = true">
    <V2InputField
      name="header-v2-search-placeholder"
      type="text"
      placeholder="Find the perfect gift"
      icon="v6-light-magnifying-glass"
      class="pointer-events-none"
    />
  </div>

  <!-- Modal -->
  <MobileModal v-model:open="open">
    <SearchBarMobileForm @close="open=false"/>
  </MobileModal>
</template>
