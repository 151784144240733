<script setup lang="ts">
import { Menu, MenuItems, MenuItem, MenuButton } from '@headlessui/vue'

import V2Card from '@consumer/components/V2/V2Card.vue'

import { openHowGiftlyWorksModal } from '@consumer/services/modals'
import { userMenuFocused } from '@consumer/logic/v2/header'
import type { CurrentUser } from '@consumer/types/serializers'
import UserMenuButton from './UserMenu/MenuButton.vue'
import UserMenuBuyAGift from './UserMenu/BuyAGift.vue'
import UserMenuItem from './UserMenu/MenuItem.vue'

withDefaults(defineProps<{
  currentUser?: CurrentUser | null
}>(), {  })

const open = ref(false)

watchEffect(() => {
  userMenuFocused.value = open.value
})
</script>

<template>
  <div class="flex flex-row gap-5 items-center text-v2-secondary">
    <Menu
      as="div"
      class="relative inline-block text-left"
      @mouseenter="open = true"
      @mouseleave="open = false"
      @click="open = !open"
    >
      <MenuButton :as="UserMenuButton" :currentUser="currentUser"/>

      <Transition name="slide-down">
        <div v-show="open">
          <MenuItems
            static
            :as="V2Card"
            :class="[
              'absolute right-0 origin-top-right mt-2 !p-0',
              'flex flex-row items-stretch bg-blend-darken',
            ]"
          >
            <div
              :class="[
                'flex flex-row items-stretch',
              ]"
            >
              <div>
                <UserMenuBuyAGift class="!h-full !w-[300px] !rounded-v2md"/>
              </div>
              <div class="w-[164px]">
                <div class="p-[12px] space-y-[12px] border-b-1 border-b-solid border-b-v2-grey-cool">
                  <MenuItem v-slot="{ active }">
                    <UserMenuItem
                      :active="active"
                      :label="currentUser ? `My Account` : `Sign Up`"
                      icon="v6-light-user"
                      iconColor="#00A2F1"
                      iconBgColor="#F3F9FF"
                      :url="currentUser ? `/my-account/purchases` : `/signup`"
                    />
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <UserMenuItem
                      :active="active"
                      :label="currentUser ? `Logout` : `Login`"
                      icon="v6-light-arrow-right-from-bracket"
                      iconColor="#00A2F1"
                      iconBgColor="#F3F9FF"
                      :url="currentUser ? `/signout` : `/signin`"
                    />
                  </MenuItem>
                </div>
                <div class="p-[12px] space-y-[12px]">
                  <MenuItem v-slot="{ active }">
                    <UserMenuItem
                      :active="active"
                      label="Browse"
                      icon="v6-light-gift"
                      iconColor="#61BF49"
                      iconBgColor="#E4FEDD"
                      url="/browse"
                    />
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <UserMenuItem
                      :active="active"
                      label="How it works"
                      icon="v6-light-circle-info"
                      iconColor="#00A2F1"
                      iconBgColor="#F3F9FF"
                      url="/how-it-works"
                      @click.prevent="openHowGiftlyWorksModal"
                    />
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <UserMenuItem
                      :active="active"
                      label="Help"
                      icon="v6-light-headset"
                      iconColor="#A675C3"
                      iconBgColor="#F7F0FA"
                      url="/support"
                    />
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <UserMenuItem
                      :active="active"
                      label="Redeem"
                      icon="v6-light-gift-card"
                      iconColor="#FFBC10"
                      iconBgColor="#FFF6DE"
                      url="/redeem"
                    />
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <UserMenuItem
                      :active="active"
                      label="For Business"
                      icon="v6-light-briefcase"
                      iconColor="#AE3B00"
                      iconBgColor="#FFDFCE"
                      url="/corp"
                    />
                  </MenuItem>
                </div>
              </div>
            </div>
          </MenuItems>
        </div>
      </Transition>
    </Menu>
  </div>
</template>
