<script setup lang="ts">
import imageTermsRestaurants from '@consumer/assets/images/header-v2/suggested-terms/restaurants.png'
import imageTermsShein from '@consumer/assets/images/header-v2/suggested-terms/shein.png'
import imageTermsShopping from '@consumer/assets/images/header-v2/suggested-terms/shopping.png'
import imageTermsStarbucks from '@consumer/assets/images/header-v2/suggested-terms/starbucks.png'
import imageTermsWholeFoods from '@consumer/assets/images/header-v2/suggested-terms/whole-foods.png'
import imageTermsTonysPizza from '@consumer/assets/images/header-v2/suggested-terms/tonys-pizza.png'
import imageTermsMadisonSquareGarden from '@consumer/assets/images/header-v2/suggested-terms/madison-square-garden.png'
import type { Suggestion } from '@consumer/logic/v2/header'

const suggestions = [
  {
    label: 'Popular',
    icon: 'v6-light-fire',
  },
  {
    label: 'Starbucks',
    image: imageTermsStarbucks,
  },
  {
    label: 'Shein',
    image: imageTermsShein,
  },
  {
    label: 'Whole Foods',
    image: imageTermsWholeFoods,
  },
  {
    label: 'Restaurants',
    image: imageTermsRestaurants,
  },
  {
    label: 'Shopping',
    image: imageTermsShopping,
  },
  {
    label: 'Tony\'s Pizza',
    city: 'San Francisco, CA',
    image: imageTermsTonysPizza,
  },
  {
    label: 'Madison Square Garden',
    city: 'New York, NY',
    image: imageTermsMadisonSquareGarden,
  },
]

const emit = defineEmits<{(e: 'select', value: Suggestion): void}>()

const selectSuggestion = (suggestion: Suggestion) => {
  emit('select', suggestion)
}
</script>

<template>
  <V2List class="w-full">
    <V2ListItem
      v-for="suggestion in suggestions"
      :key="suggestion.label"
      class="w-full cursor-pointer"
      :label="suggestion.label"
      :icon="suggestion.icon"
      :image="suggestion.image"
      @click="selectSuggestion(suggestion)"
    />
  </V2List>
</template>
