
<script setup lang="ts">import { ref as _ref } from 'vue';

import type { Component } from 'vue'
import { Popover, PopoverPanel } from '@headlessui/vue'
import { onClickOutside } from '@vueuse/core'
import type { Suggestion } from '@consumer/logic/v2/header'
import SuggestionCategory from './SuggestionCategory.vue'
import SuggestionCity from './SuggestionCity.vue'
import InputField from './InputField.vue'

withDefaults(defineProps<{
  name: string
  placeholder?: string
  compact?: boolean
}>(), {  })

const model = defineModel<string>()

interface Emit {
  (event: 'focus'): void
  (event: 'blur'): void
  (event: 'selectSuggestion', suggestion: Suggestion): void
}
const emit = defineEmits<Emit>()

let openSuggestions = _ref(false)

const onFocus = () => {
  emit('focus')
  openSuggestions.value = true
}

const onBlur = () => {
  openSuggestions.value = false
  emit('blur')
}

const onInput = () => {
  if (model.value) { openSuggestions.value = false }
  else { openSuggestions.value = true }
}

const selectSuggestion = (suggestion: Suggestion) => {
  emit('selectSuggestion', suggestion)
  onBlur()
}

const wrapperRef = ref<HTMLElement | null>(null)
onClickOutside(wrapperRef, () => {
  onBlur()
})

const SUGGESTION_COMPONENT: { [key: string]: Component } = {
  category: SuggestionCategory,
  city: SuggestionCity,
}

const suggestionComponent = computed(() => SUGGESTION_COMPONENT[__props.name])
</script>

<template>
  <Popover
    ref="wrapperRef"
    :class="[
      'w-full h-full flex flex-row relative transition-all duration-300 ease-in-out',
      compact ? 'w-[139px]' : 'w-[260px]',
    ]"
  >
    <div class="w-full h-full relative">
      <div class="w-full h-full relative">
        <InputField
          v-model="model"
          :name="name"
          :placeholder="placeholder"
          :compact="compact"
          :class="[
            'header-v2-search-input-field-desktop',
            compact ? 'header-v2-search-input-field-desktop-compact' : '',
          ]"
          @focus="onFocus"
          @input="onInput"
        />
      </div>
      <Transition name="slide-down">
        <div v-show="openSuggestions" class="w-full">
          <PopoverPanel static class="w-full absolute mt-1.5 -mx-1 z-5">
            <component :is="suggestionComponent" @select="selectSuggestion"/>
          </PopoverPanel>
        </div>
      </Transition>
    </div>
  </Popover>
</template>

<style lang="scss">
.header-v2-search-input-field-desktop {
  &.header-v2-search-input-field-desktop-compact {
    input {
      padding: 8px 12px !important;
    }
  }
}
</style>
