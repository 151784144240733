<script setup lang="ts">
import { Link } from '@inertiajs/vue3'

import { pageTitle } from '@consumer/logic/v2/header'

import {
  mobileSummaryOpen,
  scrollToMobileSummary,
  useCheckoutStore,
} from '@consumer/stores/checkout'
import { formatCurrency } from '@consumer/utils/currency'
import checkoutConfig from '@consumer/services/checkoutConfig'
import type { CurrentUser } from '@consumer/types/serializers'
import GiftlyLogo from './Header/GiftlyLogo.vue'
import UserMenu from './Header/UserMenu.vue'

withDefaults(defineProps<{
  currentUser?: CurrentUser | null
}>(), {  })

const checkoutData = useCheckoutStore()

const onClickMobileSummaryCarousel = () => {
  if (!checkoutData.readyToCheckout) {
    mobileSummaryOpen.value = !mobileSummaryOpen.value
  }
  else {
    scrollToMobileSummary.value = true
  }
}
</script>

<template>
  <div class="w-full z-100 bg-white">
    <V2Container class="!p-0 w-full">
      <div
        :class="[
          'w-full',
          'h-[58px] lg:h-[84px]',
          'mt-200',
          'px-300 pt-300 pb-v2-1 lg:px-600',
          'transition transition-all duration-200 ease-in-out',
          'flex items-end'
        ]"
      >
        <div class="w-full flex items-center justify-between h-[60px]">
          <div class="flex flex-row items-center gap-400">
            <GiftlyLogo/>
            <Link :href="pageTitle.href ?? '/'" class="no-underline group">
              <h1 class="text-[14px] leading-[140%] font-medium text-v2-secondary hover:text-v2-primary">
                {{ pageTitle.text }}
              </h1>
            </Link>
          </div>

          <div class="flex flex-row items-center gap-v2-2 lg:gap-v2-4">
            <div>
              <div
                :class="[
                  'lg:hidden',
                  'h-[44px] px-v2-4 py-v2-3',
                  'flex flex-row items-center gap-v2-2',
                  'border-1 border-solid border-v2-grey-cool rounded-v2md',
                  'text-v2-primary font-semibold',
                ]"
              >
                <div class="flex-1 text-right">Total:</div>
                <div v-if="checkoutConfig.loaded">{{ formatCurrency(checkoutData.total) }}</div>
                <div v-else>
                  <V2Icon name="v6-light-loader" class="animate-spin"/>
                </div>
                <div class="clickable" @click="onClickMobileSummaryCarousel">
                  <V2Icon
                    :name="!mobileSummaryOpen || checkoutData.readyToCheckout ?
                      'v6-light-chevron-down' :
                      'v6-light-chevron-up'"
                  />
                </div>
              </div>
            </div>
            <UserMenu :currentUser="currentUser"/>
          </div>
        </div>
      </div>
    </V2Container>
  </div>
</template>
